import React from 'react'
import './style.sass'

const Links = [{
  title: 'Github',
  url: 'https://github.com/amazingandyyy',
  fa: 'github'
},
// {
//   title: 'Meeting',
//   url: 'https://amazingandyyy.com/cal',
//   fa: 'calendar'
// },
{
  title: 'LinkedIn',
  url: 'https://www.linkedin.com/in/amazingandyyy',
  fa: 'linkedin'
},
{
  title: 'Duolingo',
  url: 'https://www.duolingo.com/profile/amazingandyyy',
  fa: 'book'
},
//   {
//   title: 'Resume',
//   url: 'https://amazingandyyy.com/resume/Andy_Chen_Resume_2024.pdf',
//   fa: 'address-card'
// }
// ,{
//   title: 'Twitter',
//   url: 'https://twitter.com/amazingandyyyer',
//   fa: 'twitter'
// }
]

export default () => (<div>
  <div className='link-component'>
    {Links.map(link => {
      return (
        <a rel='noreferrer' href={link.url} target='_blank' key={link.url}>
          <div className={'link ' + link.title}>
            <i className={'fa fa-' + link.fa} />
          </div>
        </a>
      )
    })}
  </div>
</div>)
